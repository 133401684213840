import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  collection,
  getDocs,
  serverTimestamp,
  updateDoc,
  doc,
  query,
  limit,
  orderBy,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import TablaCotizaciones from "./TablaCotizaciones";
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import Modal from "react-modal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
moment.locale("es");
// Establece la función de inicialización de react-modal para evitar un aviso de desenfoque de accesibilidad
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    maxWidth: "800px",
    width: "100%",
    height: "500px",
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif", // Aplica la fuente Roboto
    "::-webkit-scrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    "-ms-overflow-style": "none",
    /* Ocultar scrollbar en Firefox */
    "scrollbar-width": "none",
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "50%", // Centra el botón de agregar
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    fontWeight: "700",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    border: "none",
    fontFamily: "Roboto, sans-serif",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  h2: {
    fontSize: "1.5rem",
    color: "#333",
    marginBottom: "20px",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  label: {
    fontSize: "1rem",
    color: "#666",
    marginBottom: "5px",
  },
  input: {
    padding: "10px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "1px solid #ddd",
    outline: "none",
    transition: "border-color 0.3s ease",
  },
  inputFocus: {
    borderColor: "#007bff",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    flex: "1", // Make buttons take equal space
    margin: "0 5px", // Space between buttons
  },
  buttonCancel: {
    backgroundColor: "#6c757d",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  buttonCancelHover: {
    backgroundColor: "#5a6268",
  },
};

function CotizacionForm(props) {
  const [numeroCotizacion, setNumeroCotizacion] = useState("");
  const [cliente, setCliente] = useState("");
  const [clientes, setClientes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [asunto, setAsunto] = useState("");
  const [fechaCotizacion] = useState(getCurrentDate());
  const [fechaVencimiento, setFechaVencimiento] = useState(null);
  const [atencion, setAtencion] = useState("");
  const [planta, setPlanta] = useState("");
  const [area, setArea] = useState("");
  const [referencia, setReferencia] = useState("");
  const [vendedores, setVendedores] = useState([]);
  const [vendedorNombre, setVendedorNombre] = useState("");
  const [nuevoVendedorNombre, setNuevoVendedorNombre] = useState("");
  const [nuevoVendedorCorreo, setNuevoVendedorCorreo] = useState("");
  const [modalVendedorIsOpen, setModalVendedorIsOpen] = useState(false);

  const [datosSeleccionados, setDatosSeleccionados] = useState([
    {
      id: 1,
      nombre: "",
      modelo: "",
      cantidad: 1,
      tarifa: 0,
      iva: 0,
      importe: 0,
    },
  ]);
  const [descuento, setDescuento] = useState(0);
  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [mostrarTabla, setMostrarTabla] = useState(false);
  const [mostrarPrevia, setMostrarPrevia] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [estado, setEstado] = useState("pendiente");
  const { cotizacion } = props;
  const [modoEdicion, setModoEdicion] = useState(false);
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);
  const [events, setEvents] = useState([]); // Array para los eventos del calendario

  // Función para cargar productos reales desde Firestore
  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const productosSnapshot = await getDocs(collection(db, "productos"));
        const productosList = productosSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProductos(productosList);
      } catch (error) {
        console.error("Error al cargar los productos desde Firestore", error);
      }
    };

    fetchProductos();
  }, []);

  const handleInputChange = (e, productoId) => {
    const nombreProducto = e.target.value;

    // Encuentra el producto en la lista de productos reales desde Firestore
    const productoEncontrado = productos.find(
      (prod) => prod.nombre === nombreProducto
    );

    if (productoEncontrado) {
      // Actualiza los datos del producto seleccionado en la misma fila
      setDatosSeleccionados((prevDatos) =>
        prevDatos.map((item) =>
          item.id === productoId
            ? {
                ...item,
                nombre: productoEncontrado.nombre,
                numeroDeParte: productoEncontrado.numeroDeParte,
                tarifa: productoEncontrado.costo,
                iva: 0.16, // Aplica IVA del 16%
                importe: productoEncontrado.costo * item.cantidad * (1 + 0.16), // Calcula el importe con IVA
              }
            : item
        )
      );
    } else {
      // Si el producto no se encuentra, solo actualiza el nombre
      setDatosSeleccionados((prevDatos) =>
        prevDatos.map((item) =>
          item.id === productoId ? { ...item, nombre: nombreProducto } : item
        )
      );
    }
  };

  useEffect(() => {
    const fetchCotizaciones = async () => {
      const firestore = getFirestore();
      const cotizacionesRef = collection(firestore, "cotizaciones");
      const unsubscribeCotizaciones = onSnapshot(
        cotizacionesRef,
        (snapshot) => {
          const cotizaciones = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Filtrar y ordenar cotizaciones
          const proximas = cotizaciones
            .filter((cotizacion) =>
              moment(cotizacion.fechaVencimiento).isSameOrAfter(
                moment().startOf("day")
              )
            )
            .sort(
              (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
            );

          setProximasAVencer(proximas.slice(0, 6)); // Limitar la lista a 6 fechas próximas

          // Crear eventos para cotizaciones
          const cotizacionesEvents = proximas.map((cotizacion) => ({
            id: `cotizacion-${cotizacion.id}`,
            title: `Cotización #${cotizacion.numeroCotizacion}`,
            start: moment(cotizacion.fechaVencimiento).startOf("day").toDate(),
            end: moment(cotizacion.fechaVencimiento).endOf("day").toDate(),
            allDay: true,
            resource: "cotizacion",
            style: {
              backgroundColor: "blue",
              padding: "auto",
              fontSize: "12px",
              width: "80%",
              margin: "0 auto",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "auto",
            },
          }));

          // Actualizar el estado de eventos
          setEvents((prevEvents) => {
            const eventMap = new Map(
              prevEvents
                .filter((event) => !event.id.startsWith("cotizacion-"))
                .map((event) => [event.id, event])
            );
            cotizacionesEvents.forEach((event) =>
              eventMap.set(event.id, event)
            );
            return Array.from(eventMap.values());
          });
        }
      );

      return () => unsubscribeCotizaciones();
    };

    const fetchEventos = async () => {
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
        const eventos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtrar y ordenar eventos
        const proximos = eventos
          .filter((evento) =>
            moment(evento.to).isSameOrAfter(moment().startOf("day"))
          )
          .sort((a, b) => moment(a.to) - moment(b.to));

        setProximosEventos(proximos.slice(0, 6)); // Limitar la lista a 6 eventos próximos

        // Crear eventos para el calendario
        const eventosCalendario = proximos.map((evento) => ({
          id: `evento-${evento.id}`,
          title: evento.title,
          start: moment(evento.to).startOf("day").toDate(),
          end: moment(evento.to).endOf("day").toDate(),
          allDay: true,
          resource: "evento",
          style: {
            backgroundColor: "#229954",
            padding: "auto",
            fontSize: "12px",
            width: "80%",
            margin: "0 auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "auto",
          },
        }));

        // Actualizar el estado de eventos
        setEvents((prevEvents) => {
          const eventMap = new Map(
            prevEvents
              .filter((event) => !event.id.startsWith("evento-"))
              .map((event) => [event.id, event])
          );
          eventosCalendario.forEach((event) => eventMap.set(event.id, event));
          return Array.from(eventMap.values());
        });
      });

      return () => unsubscribeEventos();
    };

    // Obtener fechas festivas
    const getFechasFestivas = () => {
      const fechasFestivasBase = [
        { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
        {
          title: "Día de la Constitución",
          month: "02",
          day: "05",
          color: "#de2e03",
        },
        {
          title: "Natalicio de Benito Juárez",
          month: "03",
          day: "21",
          color: "#de2e03",
        },
        { title: "Día del Trabajo", month: "05", day: "01", color: "#de2e03" },
        {
          title: "Independencia de México",
          month: "09",
          day: "16",
          color: "#de2e03",
        },
        {
          title: "Transición del Poder Ejecutivo",
          month: "10",
          day: "01",
          color: "#de2e03",
        },
        {
          title: "Revolución Mexicana",
          month: "11",
          day: "20",
          color: "#de2e03",
        },
        { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
      ];

      const generarFechasFestivas = (años) => {
        const fechasFestivas = [];
        años.forEach((año) => {
          fechasFestivasBase.forEach((festivo) => {
            const fecha = moment(
              `${año}-${festivo.month}-${festivo.day}`,
              "YYYY-MM-DD"
            ).toDate();
            fechasFestivas.push({
              id: `festivo-${festivo.title}-${año}`,
              title: festivo.title,
              start: fecha,
              end: fecha,
              allDay: true,
              resource: "festivo",
              style: {
                backgroundColor: "#de2e03",
                padding: "auto",
                fontSize: "12px",
                width: "80%",
                margin: "0 auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "auto",
              },
            });
          });
        });
        return fechasFestivas;
      };

      const años = Array.from(
        { length: 6 },
        (_, i) => new Date().getFullYear() + i
      );
      return generarFechasFestivas(años);
    };

    // Obtener fechas festivas y fusionarlas con los eventos existentes
    const fechasFestivas = getFechasFestivas();
    setEvents((prevEvents) => [
      ...prevEvents.filter((event) => !event.id.startsWith("festivo-")),
      ...fechasFestivas,
    ]);

    // Ejecutar las funciones de carga de cotizaciones y eventos
    fetchCotizaciones();
    fetchEventos();
  }, []);

  useEffect(() => {
    if (cotizacion) {
      setModoEdicion(true);
      // Llenar el formulario con los datos de la cotización, incluyendo el número de cotización
      setCliente(cotizacion.cliente);
      setAsunto(cotizacion.asunto);
      setFechaVencimiento(cotizacion.fechaVencimiento);
      setEstado(cotizacion.estado);
      setProductosSeleccionados(cotizacion.productosSeleccionados);
      setNumeroCotizacion(cotizacion.numeroCotizacion); // Aquí se asigna el número de cotización al estado
      // Resto de los campos...
    } else {
      obtenerNumeroCotizacion();
    }
  }, [cotizacion]);

  const obtenerNumeroCotizacion = async () => {
    try {
      const cotizacionRef = collection(db, "cotizaciones");
      const q = query(
        cotizacionRef,
        orderBy("numeroCotizacion", "desc"),
        limit(1)
      );
      const cotizacionesSnapshot = await getDocs(q);
      let siguienteNumero = 1;
      if (!cotizacionesSnapshot.empty) {
        const ultimoDoc = cotizacionesSnapshot.docs[0];
        const ultimoNumero = ultimoDoc.data().numeroCotizacion;
        siguienteNumero = ultimoNumero + 1;
      }
      const numeroCotizacionFormateado = siguienteNumero
        .toString()
        .padStart(4, "0");
      setNumeroCotizacion(numeroCotizacionFormateado);
    } catch (error) {
      console.error(
        "Error al obtener el siguiente número de cotización:",
        error
      );
    }
  };

  useEffect(() => {
    obtenerClientes();
    obtenerProductos();
  }, []);

  const handleSelectChange = (e) => {
    if (e.target.value === "agregar-nuevo") {
      setModalVendedorIsOpen(true);
    } else {
      setVendedorNombre(e.target.value);
    }
  };

  const handleAgregarVendedor = () => {
    if (nuevoVendedorNombre.trim() !== "") {
      const nuevoVendedor = {
        nombre: nuevoVendedorNombre,
        correo: nuevoVendedorCorreo,
      };

      // Agregar el nuevo vendedor a la lista
      setVendedores([...vendedores, nuevoVendedor.nombre]);

      // Establecer el nombre del vendedor actual
      setVendedorNombre(nuevoVendedor.nombre);

      // Limpiar los campos del formulario
      setNuevoVendedorNombre("");
      setNuevoVendedorCorreo("");

      // Cerrar el modal de vendedor
      setModalVendedorIsOpen(false);

      // Mostrar la alerta de éxito
      Swal.fire({
        icon: "success",
        title: "Vendedor guardado correctamente",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const closeModalVendedor = () => {
    setModalVendedorIsOpen(false);
    setNuevoVendedorNombre("");
    setNuevoVendedorCorreo("");
  };

  /*const abrirModalPrevia = () => {
    setMostrarPrevia(true);
    setModalIsOpen(true);
    actualizarUltimaInteraccion();
  };*/

  const cerrarModalPrevia = () => {
    setMostrarPrevia(false);
    setModalIsOpen(false);
  };

  const calcularTotal = () => {
    const subtotal = calcularSubtotal();
    const descuentoAplicado = subtotal * (descuento / 100);
    return subtotal - descuentoAplicado;
  };

  const manejarCambioDescuento = (e) => {
    const valor = e.target.value;

    // Si el valor es un número y no empieza con un cero
    if (valor === "" || /^\d+$/.test(valor)) {
      setDescuento(valor);
    }
  };

  const obtenerClientes = async () => {
    try {
      const clientesSnapshot = await getDocs(collection(db, "clientes"));
      const listaClientes = clientesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClientes(listaClientes);
    } catch (error) {
      console.error("Error al obtener clientes:", error);
    }
  };

  const obtenerProductos = async () => {
    try {
      const productosSnapshot = await getDocs(collection(db, "productos"));
      const listaProductos = productosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        tipoDescuento: "cantidad",
        valorDescuento: 0,
      }));
      setProductos(listaProductos);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    }
  };

  const continuarDesdePrevia = () => {
    setMostrarTabla(true);
  };

  function getCurrentDate() {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0"); // Día en formato dos dígitos

    // Mapeo manual de meses a formato de tres letras
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()]; // Obtener el mes actual del arreglo

    const year = date.getFullYear(); // Año en formato cuatro dígitos

    return `${day} ${month} ${year}`;
  }

  /*function agregarProducto() {
    if (productoSeleccionado) {
      const producto = productos.find(p => p.id === productoSeleccionado);
      if (producto) {
        setProductosSeleccionados([...productosSeleccionados, { ...producto, productoIdEditado: producto.id }]);
        setProductoSeleccionado('');
      }
    }
  }*/

  const eliminarProducto = (id) => {
    // Aquí colocas la lógica para eliminar el producto basado en el id
    // Por ejemplo, puedes filtrar el array de productos para eliminar el producto con el id especificado
    setDatosSeleccionados(
      datosSeleccionados.filter((producto) => producto.id !== id)
    );
  };

  const actualizarCantidad = (productoId, nuevaCantidad) => {
    setDatosSeleccionados((prevDatos) =>
      prevDatos.map((item) =>
        item.id === productoId
          ? {
              ...item,
              cantidad: nuevaCantidad,
              importe: item.tarifa * nuevaCantidad * (1 + item.iva), // Recalcula el importe con IVA
            }
          : item
      )
    );
  };

  const actualizarCotizacionExistente = async (
    cotizacionId,
    cotizacionData
  ) => {
    try {
      // Aquí debes realizar la lógica para actualizar la cotización existente en tu base de datos
      // Por ejemplo, podrías utilizar Firebase Firestore para actualizar el documento de la cotización

      // Primero, obtén la referencia al documento de la cotización que deseas actualizar
      const cotizacionRef = doc(db, "cotizaciones", cotizacionId);

      // Luego, utiliza la función `updateDoc` para actualizar los datos de la cotización
      await updateDoc(cotizacionRef, cotizacionData);

      // Si la actualización se realiza correctamente, no es necesario hacer nada más
    } catch (error) {
      // Manejo de errores
      console.error("Error al actualizar la cotización:", error);
      throw error; // Puedes lanzar el error para que sea manejado por la función `guardar`
    }
  };

  const guardar = async () => {
    try {
      if (
        !cliente ||
        !asunto ||
        !fechaVencimiento ||
        productosSeleccionados.length === 0
      ) {
        Swal.fire({
          icon: "warning",
          title: "Campos Incompletos",
          text: "Por favor, complete todos los campos obligatorios.",
          confirmButtonColor: "#007bff",
          confirmButtonText: "Aceptar",
        });
        return;
      }

      const cotizacionData = {
        numeroCotizacion, // Asegúrate de incluir el número de cotización aquí
        cliente,
        asunto,
        referencia,
        fechaCotizacion,
        fechaVencimiento,
        estado,
        productosSeleccionados,
        total: calcularTotal(),
        createdAt: serverTimestamp(),
      };

      if (modoEdicion) {
        await actualizarCotizacionExistente(cotizacion.id, cotizacionData);
      } else {
        await props.guardarCotizacion(cotizacionData);
      }

      Swal.fire({
        icon: "success",
        title: "Cotización Guardada",
        showConfirmButton: false,
        timer: 1000,
      }).then(() => {
        window.location.reload();
      });
    } catch (error) {
      console.error("Error al guardar la cotización:", error);
    }
  };

  const actualizarIVA = (productoId, nuevoIVA) => {
    setDatosSeleccionados((prevDatos) =>
      prevDatos.map((item) =>
        item.id === productoId
          ? {
              ...item,
              iva: nuevoIVA,
              importe: item.tarifa * item.cantidad * (1 + nuevoIVA), // Recalcula el importe con el nuevo IVA
            }
          : item
      )
    );
  };

  const añadirFila = (e) => {
    e.preventDefault(); // Evita el comportamiento por defecto del botón
    const nuevaFila = {
      id: datosSeleccionados.length + 1,
      nombre: "",
      numeroDeParte: "",
      cantidad: 1,
      tarifa: 0,
      iva: 0,
      importe: 0,
    };

    setDatosSeleccionados((prevDatos) => [...prevDatos, nuevaFila]);
  };

  const agregarArticulosAGranel = (articulos) => {
    // Lógica para agregar artículos a granel
    console.log("Agregando artículos a granel:", articulos);
  };

  const calcularSubtotal = () => {
    return datosSeleccionados.reduce(
      (total, producto) => total + producto.importe,
      0
    );
  };

  return (
    <>
      <Nav
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
        events={events}
      />

      <Sidebar />

      <div className="cotizacion-form">
        <form>
          <div>
            <div className="group apartado">
              <label htmlFor="cliente" className="required">
                Nombre del cliente*
              </label>
              <select
                id="cliente"
                name="cliente"
                value={cliente}
                onChange={(e) => setCliente(e.target.value)}
              >
                <option value="">Seleccionar cliente</option>
                {clientes.map((cliente) => (
                  <option key={cliente.id} value={cliente.id}>
                    {cliente.empresa}
                  </option>
                ))}
              </select>
            </div>

            <br />

            <div className="group">
              <label htmlFor="numeroCotizacion" className="required">
                No. Cotización#*
              </label>
              <input
                type="text"
                id="numeroCotizacion"
                name="numeroCotizacion"
                value={numeroCotizacion}
                onChange={(e) => setNumeroCotizacion(e.target.value)}
              />
            </div>

            <div className="group">
              <label htmlFor="referencia">Referencia</label>
              <input
                type="text"
                id="referencia"
                name="referencia"
                value={referencia}
                onChange={(e) => setReferencia(e.target.value)}
              />
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="fecha-cotizacion" className="required">
                  Fecha de cotización*
                </label>
                <input
                  type="text"
                  id="fecha-cotizacion"
                  name="fecha-cotizacion"
                  value={getCurrentDate()}
                  readOnly
                />
              </div>
              <div className="group">
                <label htmlFor="fecha-vencimiento">Fecha de vencimiento</label>
                <div>
                  <DatePicker
                    selected={fechaVencimiento}
                    onChange={(date) => setFechaVencimiento(date)}
                    dateFormat="dd MMM yyyy"
                    placeholderText="dd MMM yyyy"
                    id="fecha-vencimiento"
                  />
                </div>
              </div>
            </div>

            <hr
              style={{
                marginBottom: "30px",
                marginTop: "20px",
                border: "1px solid #f9f9fb",
              }}
            />

            <div className="group">
              <label htmlFor="vendedorNombre">Vendedor</label>
              <select
                id="vendedorNombre"
                name="vendedorNombre"
                value={vendedorNombre}
                onChange={handleSelectChange}
              >
                <option value="" disabled>
                  Seleccionar o agregar vendedor
                </option>
                {vendedores.map((vendedor, index) => (
                  <option key={index} value={vendedor}>
                    {vendedor}
                  </option>
                ))}
                <option value="agregar-nuevo">Agregar nuevo vendedor</option>
              </select>
              <br />

              {/* Modal para agregar nuevo vendedor */}
              <Modal
                isOpen={modalVendedorIsOpen}
                onRequestClose={closeModalVendedor}
                contentLabel="Agregar Nuevo Vendedor"
                style={customStyles2}
              >
                <h2 style={customStyles2.h2}>Agregar Nuevo Vendedor</h2>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAgregarVendedor();
                  }}
                  style={customStyles2.form}
                >
                  <label
                    htmlFor="nuevoVendedorNombre"
                    style={customStyles2.label}
                  >
                    Nombre (obligatorio)
                  </label>
                  <input
                    type="text"
                    id="nuevoVendedorNombre"
                    name="nuevoVendedorNombre"
                    value={nuevoVendedorNombre}
                    onChange={(e) => setNuevoVendedorNombre(e.target.value)}
                    required
                    style={customStyles2.input}
                    onFocus={(e) =>
                      (e.target.style.borderColor =
                        customStyles2.inputFocus.borderColor)
                    }
                    onBlur={(e) => (e.target.style.borderColor = "")}
                  />
                  <br />
                  <label
                    htmlFor="nuevoVendedorCorreo"
                    style={customStyles2.label}
                  >
                    Correo
                  </label>
                  <input
                    type="email"
                    id="nuevoVendedorCorreo"
                    name="nuevoVendedorCorreo"
                    value={nuevoVendedorCorreo}
                    onChange={(e) => setNuevoVendedorCorreo(e.target.value)}
                    style={customStyles2.input}
                    onFocus={(e) =>
                      (e.target.style.borderColor =
                        customStyles2.inputFocus.borderColor)
                    }
                    onBlur={(e) => (e.target.style.borderColor = "")}
                  />
                  <br />
                  <div style={customStyles2.buttonContainer}>
                    <button
                      type="button"
                      onClick={closeModalVendedor}
                      style={{
                        ...customStyles2.button,
                        ...customStyles2.buttonCancel,
                        ...customStyles2.buttonCancelHover,
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor =
                          customStyles2.buttonCancelHover.backgroundColor)
                      }
                      onMouseOut={(e) =>
                        (e.target.style.backgroundColor =
                          customStyles2.buttonCancel.backgroundColor)
                      }
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      style={{
                        ...customStyles2.button,
                        ...customStyles2.buttonHover,
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor =
                          customStyles2.buttonHover.backgroundColor)
                      }
                      onMouseOut={(e) =>
                        (e.target.style.backgroundColor =
                          customStyles2.button.backgroundColor)
                      }
                    >
                      Guardar
                    </button>
                  </div>
                </form>
              </Modal>
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="atencion" className="required">
                  Atención*
                </label>
                <input
                  type="text"
                  id="atencion"
                  name="atencion"
                  value={atencion}
                  onChange={(e) => setAtencion(e.target.value)}
                />
              </div>
              <div className="group">
                <label htmlFor="planta">Planta</label>
                <input
                  type="text"
                  id="planta"
                  name="planta"
                  value={planta}
                  onChange={(e) => setPlanta(e.target.value)}
                />
              </div>
            </div>

            <div className="group">
              <label htmlFor="area">Área</label>
              <input
                type="text"
                id="area"
                name="area"
                value={area}
                onChange={(e) => setArea(e.target.value)}
              />
            </div>

            <br />

            <div className="group">
              <label htmlFor="asunto">Asunto</label>
              <input
                type="text"
                id="asunto"
                name="asunto"
                value={asunto}
                onChange={(e) => setAsunto(e.target.value)}
              />
            </div>

            <br />

            <div className="apartado2">Tabla de productos</div>
         
            <table className="productos-table">
              <thead>
                <tr>
                  <th>Detalles del Artículo</th>
                  <th>Modelo</th>
                  <th>Cantidad</th>
                  <th>Precio</th>
                  <th>IVA</th>
                  <th>Importe</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {datosSeleccionados.map((producto) => (
                  <tr key={producto.id}>
                    <td>
                      <input
                        id="list-products"
                        list="productos-list"
                        value={producto.nombre}
                        onChange={(e) => handleInputChange(e, producto.id)}
                        placeholder="Seleccione o haga clic para elegir un artículo"
                        style={{ width: "400px", height: "102px" }} // Ajusta el valor según lo que necesites
                      />
                      <datalist id="productos-list">
                        {productos.map((prod) => (
                          <option key={prod.id} value={prod.nombre} />
                        ))}
                      </datalist>
                    </td>

                    <td>
                      <input
                        type="text"
                        value={producto.numeroDeParte}
                        readOnly
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        value={producto.cantidad}
                        onChange={(e) =>
                          actualizarCantidad(
                            producto.id,
                            parseInt(e.target.value)
                          )
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={producto.tarifa.toFixed(2)}
                        readOnly
                      />
                    </td>
                    <td>
                      <select
                        value={producto.iva}
                        onChange={(e) =>
                          actualizarIVA(producto.id, parseFloat(e.target.value))
                        }
                      >
                        <option value="0">0%</option>
                        <option value="0.08">8%</option>
                        <option value="0.16">16%</option>
                      </select>
                    </td>
                    <td>${producto.importe.toFixed(2)}</td>
                    <td>
                      <button
                        style={{ margin: "0 auto", display: "flex" }}
                        onClick={() => eliminarProducto(producto.id)}
                      >
                        <i className="bi bi-x-circle"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="acciones-tabla">
              <button onClick={añadirFila}>
                <i
                  style={{ color: "#007bff", fontSize: "16px" }}
                  className="bi bi-plus-circle-fill"
                ></i>
                Añadir nueva fila
              </button>
              <button onClick={agregarArticulosAGranel}>
                <i
                  style={{ color: "#007bff", fontSize: "16px" }}
                  className="bi bi-plus-circle-fill"
                ></i>
                Agregar artículos a granel
              </button>
            </div>

            <div className="data-container">
              <div className="notes-container">
                <label htmlFor="customer-notes">Notas del Cliente</label>
                <textarea
                  id="customer-notes"
                  placeholder="Escribe tus notas aquí..."
                ></textarea>
              </div>

              <div className="totales-container">
                <div className="row">
                  <span className="label">Subtotal</span>
                  {/* Formatear el subtotal con comas para los miles */}
                  <span>
                    $
                    {calcularSubtotal().toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <div className="row descuento">
                  <span className="label">Descuento</span>
                  <div className="descuento-input-container">
                    <input
                      type="text"
                      style={{ textAlign: "right" }}
                      value={descuento}
                      onChange={manejarCambioDescuento}
                      onBlur={() => setDescuento(parseInt(descuento) || 0)}
                    />
                    <span className="percentage-sign">%</span>
                  </div>
                  <span className="discount-percentage">{descuento}%</span>
                </div>

                <hr />

                <div className="row total">
                  <span>Total (MXN)</span>
                  {/* Formatear el total con comas para los miles */}
                  <span className="total-amount">
                    $
                    {calcularTotal().toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "#f9f9fb",
                padding: "8px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "20px",
                width: "99%",
                marginTop: "30px",
                paddingBottom: "30px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Left Section: Textarea */}
              <div style={{ width: "50%"}}>
                <h4 style={{ marginBottom: "8px", textAlign: "left" }}>
                  Términos y condiciones
                </h4>
                <textarea
                  style={{
                    width: "100%",
                    height: "130px",
                    padding: "10px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    fontSize: "14px",
                    resize: "none",
                    fontFamily: "sans-serif",
                    lineHeight: "1.5",
                  }}
                  defaultValue="Tiempo de entrega: 1 Semana después de confirmar con OC.
Para las cotizaciones elaboradas en dólares, el tipo de cambio a tomar será el especificado en el Diario Oficial de la Federación el día de la facturación y/o pago."
                />
              </div>

              {/* Right Section: File Upload */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  flex: "0 0 600px",
                  marginTop: "30px",
                }}
              >
                <label style={{ fontSize: "14px" }}>
                  Adjuntar archivo(s) a Estimación
                </label>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px dashed #ccc",
                    borderRadius: "5px",
                    overflow: "hidden", // Eliminar cualquier contenido que se salga del borde
                  }}
                >
                  <label
                    style={{
                      padding: "8px 10px",
                      backgroundColor: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      cursor: "pointer",
                      whiteSpace: "nowrap", // Evitar que el contenido del botón se ajuste en varias líneas
                    }}
                  >
                    <i style={{ color: "#000" }} className="bi bi-upload"></i>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                        fontWeight: "lighter",
                      }}
                    >
                      Cargar archivo
                    </span>
                    <input
                      type="file"
                      style={{ display: "none" }} // Ocultar el input de archivo
                      multiple
                    />
                  </label>

                  <button
                    style={{
                      padding: "10px",
                      backgroundColor: "#fff",
                      borderLeft: "1px dashed #ccc", // Borde que conecta con el input
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      borderTopRightRadius: "auto", // Bordes redondeados en la esquina derecha
                      borderBottomRightRadius: "auto",
                      width: "10px", // Dejar que el botón ajuste su tamaño
                      minWidth: "40px", // Evitar que el botón se haga demasiado pequeño
                      margin: "0", // Eliminar cualquier margen que agregue espacio extra
                    }}
                  >
                    <i
                      style={{
                        color: "#000",
                        fontSize: "14px", // Ajustar el tamaño del ícono
                      }}
                      className="bi bi-chevron-down"
                    ></i>
                  </button>
                </div>

                <p style={{ fontSize: "12px", color: "#888" }}>
                  Puede cargar un máximo de 3 archivos, cada uno de 10 MB
                </p>
              </div>
            </div>

            {/*<div style={{ textAlign: "center" }}>
              <p>Guardado por última vez: {ultimaInteraccion}</p>
              <button type="button" onClick={abrirModalPrevia}>
                Vista Previa
              </button>
              <button type="button" onClick={guardar}>
                {modoEdicion ? "Guardar" : "Guardar Cotización"}
              </button>
            </div>*/}
          </div>
        </form>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cerrarModalPrevia}
          contentLabel="Vista Previa"
          style={customStyles}
        >
          {mostrarPrevia && (
            <PreviaCotizacion
              cliente={cliente}
              clientes={clientes}
              asunto={asunto}
              fechaCotizacion={fechaCotizacion}
              productosSeleccionados={productosSeleccionados}
              continuarDesdePrevia={continuarDesdePrevia}
              numeroCotizacion={props.numeroCotizacion}
            />
          )}
          <div className="modal-buttons">
            <button onClick={cerrarModalPrevia} className="eliminarBtnModal">
              Cerrar
            </button>
          </div>
        </Modal>

        {mostrarTabla && (
          <TablaCotizaciones
            numeroCotizacion={numeroCotizacion}
            cotizaciones={props.cotizaciones}
          />
        )}
      </div>
    </>
  );
}

function PreviaCotizacion({
  cliente,
  clientes,
  asunto,
  fechaCotizacion,
  productosSeleccionados,
  numeroCotizacion,
}) {
  const nombreCliente = clientes.find((c) => c.id === cliente)?.empresa || "";
  // Calcular subtotal
  const subtotal = productosSeleccionados.reduce(
    (acc, producto) => acc + parseFloat(producto.subtotal),
    0
  );
  // Calcular descuento total (suponiendo que hay un descuento en cada producto)
  const descuentoTotal = productosSeleccionados.reduce(
    (acc, producto) => acc + parseFloat(producto.descuento),
    0
  );
  // Calcular IVA
  const iva = subtotal * 0.16; // Suponiendo que el IVA es del 16%
  // Calcular total
  const total = subtotal - descuentoTotal + iva;

  return (
    <div>
      <div className="cotizacion-header">
        <img
          src="/img/logo-iciamex.png"
          alt="ICIAMEX"
          className="logoCotizacion"
        />
        <div className="border-right"></div>
        <h1 className="cotizacion-title">Cotización</h1>
      </div>
      <h1>Previa</h1>
      <h2>Cotización: {numeroCotizacion?.toString().padStart(4, "0")}</h2>
      <hr style={{ border: "1px solid #cecece" }} />
      <p>Fecha de cotización: {fechaCotizacion}</p>
      <p>Asunto: {asunto}</p>
      <p>Cliente: {nombreCliente}</p>
      <h3>DESCRIPCIÓN</h3>
      <table className="productos-table">
        <thead>
          <tr>
            <th>Cantidad</th>
            <th>Descripción</th>
            <th>Modelo</th>
            <th>Precio</th>
            <th>Descuento</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {productosSeleccionados.map((producto) => (
            <tr key={producto.id}>
              <td>{producto.cantidad}</td>
              <td>{producto.nombre}</td>
              <td>{producto.numeroDeParte}</td>
              <td>
                $
                {parseFloat(producto.costo)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td>
                $
                {parseFloat(producto.descuento)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
              <td>
                $
                {parseFloat(producto.subtotal)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3>
        Subtotal: $
        {parseFloat(subtotal)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </h3>
      <h3>
        Descuento: $
        {parseFloat(descuentoTotal)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </h3>
      <h3>
        IVA: $
        {parseFloat(iva)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </h3>
      <h3>
        Total: $
        {parseFloat(total)
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </h3>
    </div>
  );
}

export default CotizacionForm;