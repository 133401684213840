import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";

const Notificaciones = ({ proximasAVencer, proximosEventos }) => {
  const [showNotifications, setShowNotifications] = useState(false);

  // Leer las notificaciones leídas desde localStorage
  const getReadNotifications = () => {
    const readNotifications =
      JSON.parse(localStorage.getItem("readNotifications")) || [];
    return readNotifications;
  };

  // Guardar las notificaciones leídas en localStorage
  const markAsRead = useCallback(() => {
    const readNotifications = getReadNotifications();
    const newReadNotifications = [
      ...readNotifications,
      ...proximasAVencer.map((item) => item.numeroCotizacion),
      ...proximosEventos.map((evento) => evento.id),
    ];
    localStorage.setItem(
      "readNotifications",
      JSON.stringify([...new Set(newReadNotifications)])
    );
  }, [proximasAVencer, proximosEventos]);

  useEffect(() => {
    if (showNotifications) {
      markAsRead();
    }
  }, [showNotifications, markAsRead]);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
  };

  // Filtrar notificaciones leídas
  const readNotifications = getReadNotifications();
  const unreadProximasAVencer = proximasAVencer.filter(
    (item) => !readNotifications.includes(item.numeroCotizacion)
  );
  const unreadProximosEventos = proximosEventos.filter(
    (evento) => !readNotifications.includes(evento.id)
  );

  return (
    <div className="notificaciones-container">
      <div className="notification-icon-container">
        <i
          className="bi bi-bell notification-icon"
          onClick={handleNotificationClick}
        ></i>
        {(unreadProximasAVencer.length > 0 ||
          unreadProximosEventos.length > 0) &&
          !showNotifications && (
            <div className="notification-badge">
              {unreadProximasAVencer.length + unreadProximosEventos.length}
            </div>
          )}
      </div>
      {showNotifications && (
        <div className="notifications-dropdown">
          <ul className="notifications-list">
            {unreadProximasAVencer.length > 0 && (
              <>
                <h4 className="notification-header">Nueva Cotización:</h4>
                {unreadProximasAVencer.map((item, index) => (
                  <li key={index} className="notification-item">
                    <strong className="notification-title">
                      Cotización #
                      {item.numeroCotizacion?.toString().padStart(4, "0")}
                    </strong>
                    <span className="notification-date">
                      Vence el{" "}
                      {moment(item.fechaVencimiento).format("DD/MM/YYYY")}
                    </span>
                  </li>
                ))}
              </>
            )}
            {unreadProximosEventos.length > 0 && (
              <>
                <h4 className="notification-header">Nuevo Evento:</h4>
                {unreadProximosEventos.map((evento, index) => (
                  <li key={index} className="notification-item">
                    <strong className="notification-title">
                      {evento.title}
                    </strong>
                    <span className="notification-date">
                      Vence el {moment(evento.to).format("DD/MM/YYYY")}
                    </span>
                  </li>
                ))}
              </>
            )}
            {/* Mostrar todo el historial */}
            {proximasAVencer.length > 0 && (
              <>
                <h4 className="notification-header">Cotizaciones a Vencer:</h4>
                {proximasAVencer.map((item, index) => (
                  <li
                    key={`historial-cot-${index}`}
                    className="notification-item"
                  >
                    <strong className="notification-title">
                      Cotización #
                      {item.numeroCotizacion?.toString().padStart(4, "0")}
                    </strong>
                    <span className="notification-date">
                      Vence el{" "}
                      {moment(item.fechaVencimiento).format("DD/MM/YYYY")}
                    </span>
                  </li>
                ))}
              </>
            )}
            {proximosEventos.length > 0 && (
              <>
                <h4 className="notification-header">Próximos Eventos:</h4>
                {proximosEventos.map((evento, index) => (
                  <li
                    key={`historial-evento-${index}`}
                    className="notification-item"
                  >
                    <strong className="notification-title">
                      {evento.title}
                    </strong>
                    <span className="notification-date">
                      Vence el {moment(evento.to).format("DD/MM/YYYY")}
                    </span>
                  </li>
                ))}
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Notificaciones;
