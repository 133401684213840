import React from 'react'
import ArticulosVenta from '../components/ArticulosVenta'

const articulosDeVenta = () => {
  return (

    <ArticulosVenta />
    
  )
}

export default articulosDeVenta