import React, { useState, useEffect, useCallback } from 'react';

// Componente FullScreen
const FullScreen = ({ onToggleFullscreen }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Función para verificar el estado de pantalla completa
  const checkFullscreenStatus = useCallback(() => {
    setIsFullscreen(!!document.fullscreenElement);
  }, []);

  // Manejar cambios en el estado de pantalla completa
  useEffect(() => {
    document.addEventListener('fullscreenchange', checkFullscreenStatus);
    checkFullscreenStatus();

    return () => {
      document.removeEventListener('fullscreenchange', checkFullscreenStatus);
    };
  }, [checkFullscreenStatus]);

  const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
        .catch(err => {
          console.error('Error al solicitar pantalla completa:', err);
        });
    } else {
      document.exitFullscreen()
        .catch(err => {
          console.error('Error al salir de pantalla completa:', err);
        });
    }
    
    // Actualizar el estado en localStorage
    localStorage.setItem('isFullscreen', !isFullscreen);

    setIsFullscreen(prevState => !prevState);
    if (onToggleFullscreen) {
      onToggleFullscreen();
    }
  };

  return (
    <div className="fullscreen-container">
      <i 
        className={`bi ${isFullscreen ? 'bi-arrows-collapse' : 'bi-arrows-fullscreen'} fullscreen-icon`} 
        onClick={handleFullscreenToggle}
        aria-label={isFullscreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}
      />
    </div>
  );
};

export default FullScreen;
