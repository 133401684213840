import React from 'react';
import NuevaCotizacion from '../components/NuevaCotizacion';

function CotizacionesContainer() {
  return (
    <div>
      <NuevaCotizacion />
    </div>
  );
}

export default CotizacionesContainer;
