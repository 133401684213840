import React from 'react'
import NuevaOrdenCompra from '../components/NuevaOrdenCompra';

const orden = () => {
  return (
    <div>
      <NuevaOrdenCompra />

    </div>
  )
}

export default orden