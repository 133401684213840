import React, { useState, useEffect } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import EditarCotizacionForm from "./EditarCotizacionForm";

const styles = StyleSheet.create({
  pdfContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pdfViewer: {
    width: "85%",
    height: "85%",
    border: "1px solid #ccc",
    borderRadius: 5,
    overflow: "hidden",
  },
  page: {
    padding: 30,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginTop: "20px",
  },
  logoContainer: {
    width: "50%",
    marginTop: "5px",
  },
  logo: {
    width: "150px",
    height: "auto",
  },
  headerInfo: {
    width: "50%",
    textAlign: "right",
  },
  companyName: {
    fontSize: "10px",
    fontWeight: "bold",
  },
  companyDetails: {
    fontSize: "10px",
    marginTop: "2px",
  },
  titleSection: {
    textAlign: "center",
    marginBottom: "20px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#1976d2",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  clientInfo: {
    width: "50%",
    fontSize: "10px",
  },
  infoTitle: {
    fontSize: "10px",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  infoText: {
    fontSize: "10px",
    marginBottom: "3px",
  },
  infoTextColor: {
    fontSize: "10px",
    marginBottom: "3px",
    color: "#1976d2",
    fontWeight: "bold",
  },
  infoBlessed: {
    fontSize: "10px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  cotizacionTable: {
    width: "50%",
    textAlign: "right",
    marginTop: "-10px",
  },
  table: {
    marginTop: "10px",
    width: "100%",
    borderWidth: 1,
    borderColor: "#fff",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#fff",
    backgroundColor: "#F9F9F9",
  },
  tableRowHeader: {
    flexDirection: "row",
    backgroundColor: "#6c6c6c",
    borderBottomWidth: 1,
    borderColor: "#fff",
    color: "#fff",
    fontSize: "10px",
  },
  tableCell: {
    padding: 8,
    textAlign: "left", // Cambiar a 'center' si deseas centrar el contenido
    borderRight: "1px solid #fff", // Borde derecho sutil para las celdas
    fontSize: "11px",
    lineHeight: "1.5",
  },
  tableHeader: {
    fontWeight: "bold",
  },
  closeButton: {
    color: "#fff",
  },
  tableSmall: {
    marginTop: "10px",
  },
  tableRowSmall: {
    display: "flex",
    left: "55%",
    flexDirection: "row",
    marginBottom: "3px", // Margen reducido para menor altura de fila
  },
  tableLabelSmall: {
    fontSize: "9px", // Tamaño de fuente más pequeño
    fontWeight: "bold",
    width: "40%",
    padding: "6px",
    color: "#fff",
    backgroundColor: "#6c6c6c",
    textAlign: "left",
  },
  tableValueSmall: {
    fontSize: "9px", // Tamaño de fuente más pequeño
    width: "40%",
    textAlign: "left",
    margin: "5px",
  },
  footerContainer: {
    marginTop: 10,
    alignItems: "flex-end", // Mantén los valores de subtotal, IVA, y total a la derecha
    flexDirection: "row", // Añade esta propiedad para que las condiciones de pago y los valores estén en una misma línea
    justifyContent: "space-between", // Asegura que haya espacio entre las condiciones de pago y los valores
    width: "100%", // Asegura que el contenedor ocupe el ancho completo
  },
  footerLeft: {
    alignItems: "flex-start", // Alinea el texto de las condiciones de pago a la izquierda
    width: "60%", // Ajusta el ancho de la sección de condiciones de pago
  },
  footerRight: {
    width: "40%", // Ajusta el ancho de la sección de valores (Subtotal, IVA, Total)
  },
  footerRow: {
    flexDirection: "row",
    justifyContent: "space-between", // Espacia el label y el valor
    marginBottom: 0, // Elimina el margen entre filas
  },
  footerRowGray: {
    backgroundColor: "#F9F9F9", // Fondo gris claro para subtotal y IVA
  },
  footerRowBlue: {
    backgroundColor: "#1565c0", // Fondo azul para el total
  },
  footerLabel: {
    flex: 1,
    fontWeight: "bold",
    fontSize: 11,
    textAlign: "right",
    padding: 8,
  },
  footerValue: {
    flex: 1,
    fontSize: 11,
    textAlign: "right",
    padding: 8,
    fontWeight: "bold",
  },
  footerLabelBlue: {
    color: "#fff", // Color de texto para el total
    fontWeight: "bold",
  },
  footerValueBlue: {
    color: "#fff", // Color de texto para el total
    fontWeight: "bold",
  },
});

const ResumenCotizacion = ({
  cotizacion,
  isOpen,
  onClose,
  clientes,
  setCotizaciones,
  cotizaciones,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showSummary, setShowSummary] = useState(true);
  const [pdfVisible, setPdfVisible] = useState(false);

  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose]);

  const generatePDF = () => {
    // Verificamos si la cotización está disponible
    if (!cotizacion) {
      return null;
    }

    // Calcular subtotal, descuento total, IVA y total
    const subtotal = cotizacion.productosSeleccionados.reduce(
      (acc, producto) => acc + parseFloat(producto.subtotal),
      0
    );
    const descuentoTotal = cotizacion.productosSeleccionados.reduce(
      (acc, producto) =>
        acc + (producto.descuento ? parseFloat(producto.descuento) : 0),
      0
    );
    const iva = subtotal * 0.16; // Suponiendo que el IVA es del 16%
    const total = subtotal - descuentoTotal + iva;

    return (
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          {/* Header */}
          <View style={styles.header}>
            <View style={styles.logoContainer}>
              <Image src="/img/logo-iciamex.png" style={styles.logo} />
            </View>
            <View style={styles.headerInfo}>
              <Text style={styles.companyName}>
                UNIFICACION PARA LA AUTOMATIZACION SA DE CV
              </Text>
              <Text style={styles.companyDetails}>
                Lic. Ramiro Hernández 448, Las Pintitas
              </Text>
              <Text style={styles.companyDetails}>
                El Salto, Jalisco, Mexico, C.P. 45693
              </Text>
              <Text style={styles.companyDetails}>(33) 1602 2358</Text>
              <Text style={styles.companyDetails}>UAU151222IT1</Text>
            </View>
          </View>

          {/* Title */}
          <View style={styles.titleSection}>
            <Text style={styles.title}>COTIZACIÓN</Text>
          </View>

          {/* Information Sections */}
          <View style={styles.infoContainer}>
            {/* Left Side: Cliente Information */}
            <View style={styles.clientInfo}>
              <Text style={styles.infoTitle}>Cliente:</Text>
              <Text style={styles.infoTextColor}>PRODUCTOS MAVER SA DE CV</Text>
              <Text style={styles.infoText}>
                Oleoducto 2804, Álamo Industrial,
              </Text>
              <Text style={styles.infoText}>Tlaquepaque, Jalisco</Text>
              <Text style={styles.infoText}>C.P. 45593</Text>
            </View>

            {/* Right Side: Cotización Table */}
            <View style={styles.cotizacionTable}>
              <View style={styles.tableSmall}>
                <View style={styles.tableRowSmall}>
                  <Text style={styles.tableLabelSmall}>Cotización #</Text>
                  <Text style={styles.tableValueSmall}>
                    {cotizacion.numeroCotizacion?.toString().padStart(4, "0")}
                  </Text>
                </View>
                <View style={styles.tableRowSmall}>
                  <Text style={styles.tableLabelSmall}>Fecha</Text>
                  <Text style={styles.tableValueSmall}>14 ago 2024</Text>
                </View>
                <View style={styles.tableRowSmall}>
                  <Text style={styles.tableLabelSmall}>Vencimiento</Text>
                  <Text style={styles.tableValueSmall}>16 sep 2024</Text>
                </View>
                <View style={styles.tableRowSmall}>
                  <Text style={styles.tableLabelSmall}>Vendedor</Text>
                  <Text style={styles.tableValueSmall}>
                    Cesar Arturo Zuñiga
                  </Text>
                </View>
                <View style={styles.tableRowSmall}>
                  <Text style={styles.tableLabelSmall}>Atención</Text>
                  <Text style={styles.tableValueSmall}>
                    Doriand Joan Lopez Martinez
                  </Text>
                </View>
                <View style={styles.tableRowSmall}>
                  <Text style={styles.tableLabelSmall}>Área</Text>
                  <Text style={styles.tableValueSmall}>Compras</Text>
                </View>
              </View>
            </View>
          </View>

          <Text style={styles.infoBlessed}>
            Gracias por darnos la oportunidad de hacerle una propuesta de
            nuestros equipos y/o servicios. Estamos seguros que quedará
            satisfecho con los beneficios que ofrecemos
          </Text>

          {/* Product Table */}
          <View style={styles.table}>
            {/* Encabezados de la tabla */}
            <View style={styles.tableRowHeader}>
              <Text
                style={[styles.tableCell, styles.tableHeader, { flex: 0.1 }]}
              >
                #
              </Text>
              <Text style={[styles.tableCell, styles.tableHeader, { flex: 5 }]}>
                Artículo & Descripción
              </Text>
              <Text style={[styles.tableCell, styles.tableHeader, { flex: 1 }]}>
                Modelo
              </Text>
              <Text
                style={[styles.tableCell, styles.tableHeader, { flex: 0.5 }]}
              >
                Cant.
              </Text>
              <Text style={[styles.tableCell, styles.tableHeader, { flex: 1 }]}>
                Precio Unitario
              </Text>
              <Text style={[styles.tableCell, styles.tableHeader, { flex: 1 }]}>
                Importe
              </Text>
            </View>

            {/* Filas de ejemplo */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, { flex: 0.1 }]}>1</Text>
              <Text style={[styles.tableCell, { flex: 5 }]}>
                Lorem Ipsum es simplemente el texto de relleno de las imprentas
                y archivos de texto. Lorem Ipsum es simplemente el texto de
                relleno de las imprentas y archivos de texto. Lorem Ipsum ha
                sido el texto de relleno estándar de las industrias desde el año
                1500, cuando un impresor (N. del T. persona que se dedica a la
                imprenta) desconocido usó una galería de textos y los mezcló de
                tal manera que logró hacer un libro de textos especimen. No sólo
                sobrevivió 500 años, sino que tambien ingresó como texto de
                relleno en documentos electrónicos, quedando esencialmente igual
                al original. Fue popularizado en los 60s con la creación de las
                hojas "Letraset", las cuales contenian pasajes de Lorem Ipsum, y
                más recientemente con software de autoedición, como por ejemplo
                Aldus PageMaker, el cual incluye versiones de Lorem Ipsum.
              </Text>
              <Text style={[styles.tableCell, { flex: 1 }]}>IC-S7833</Text>
              <Text style={[styles.tableCell, { flex: 0.5 }]}>1</Text>
              <Text style={[styles.tableCell, { flex: 1 }]}>7,122.00</Text>
              <Text style={[styles.tableCell, { flex: 1 }]}>7,122.00</Text>
            </View>

            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, { flex: 0.1 }]}>2</Text>
              <Text style={[styles.tableCell, { flex: 5 }]}>
                [Descripción del siguiente producto...]
              </Text>
              <Text style={[styles.tableCell, { flex: 1 }]}>IC-S7822</Text>
              <Text style={[styles.tableCell, { flex: 0.5 }]}>1</Text>
              <Text style={[styles.tableCell, { flex: 1 }]}>24,799.00</Text>
              <Text style={[styles.tableCell, { flex: 1 }]}>24,799.00</Text>
            </View>

            {/* Sección de Totales */}
            <View style={styles.footerContainer}>
              <View style={styles.footerLeft}>
                <Text style={{ fontSize: 10, marginBottom: 15, marginTop: 10 }}>
                  Condiciones de Pago:
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 4 }}>
                  50 % Anticipo
                </Text>
                <Text style={{ fontSize: 10, marginBottom: 4 }}>
                  50 % A la entrega de conformidad
                </Text>
                <Text style={{ fontSize: 10, marginTop: 10 }}>
                  Costos en Moneda Nacional
                </Text>
              </View>
              <View style={styles.footerRight}>
                <View style={[styles.footerRow, styles.footerRowGray]}>
                  <Text style={[styles.footerLabel]}>Subtotal</Text>
                  <Text style={[styles.footerValue]}>
                    $
                    {parseFloat(subtotal)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </View>
                <View style={[styles.footerRow, styles.footerRowGray]}>
                  <Text style={[styles.footerLabel]}>IVA</Text>
                  <Text style={[styles.footerValue]}>
                    $
                    {parseFloat(iva)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </View>
                <View style={[styles.footerRow, styles.footerRowBlue]}>
                  <Text style={[styles.footerLabel, styles.footerLabelBlue]}>
                    Total
                  </Text>
                  <Text style={[styles.footerValue, styles.footerValueBlue]}>
                    $
                    {parseFloat(total)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const handlePrint = () => {
    setPdfVisible(true);
  };

  const handleClosePDF = () => {
    setPdfVisible(false);
  };

  if (pdfVisible) {
    return (
      <div className="pdf-viewer-container" style={styles.pdfContainer}>
        <button
          className="cerrar-button"
          style={styles.closeButton}
          onClick={handleClosePDF}
        >
          <i className="bi bi-x-lg"></i>
        </button>
        <PDFViewer className="pdf-viewer" style={styles.pdfViewer}>
          {generatePDF()}
        </PDFViewer>
      </div>
    );
  }

  const handleEdit = () => {
    setEditMode(true);
    setShowSummary(false);
  };

  const closeModal = () => {
    setEditMode(false);
    setShowSummary(true); // Mostramos el resumen al cerrar el formulario de edición
    onClose();
  };

  if (editMode) {
    return (
      <EditarCotizacionForm
        cotizacion={cotizacion}
        clientes={clientes}
        productos={cotizacion && cotizacion.productosSeleccionados}
        setCotizaciones={setCotizaciones}
        cotizaciones={cotizaciones}
        onClose={closeModal}
      />
    );
  }

  // Si el resumen debe mostrarse y la cotización y los productos están disponibles
  if (showSummary && cotizacion && cotizacion.productosSeleccionados) {
    const { asunto, fechaCotizacion, productosSeleccionados } = cotizacion;

    // Buscamos el cliente correspondiente en la lista de clientes
    const clienteEncontrado =
      clientes && clientes.find((cliente) => cliente.id === cotizacion.cliente);

    // Verificamos si se encontró el cliente
    const nombreCliente = clienteEncontrado
      ? clienteEncontrado.empresa
      : "Cliente no encontrado";

    // Calcular subtotal, descuento total, IVA y total
    const subtotal = productosSeleccionados.reduce(
      (acc, producto) => acc + parseFloat(producto.subtotal),
      0
    );
    const descuentoTotal = productosSeleccionados.reduce(
      (acc, producto) =>
        acc + (producto.descuento ? parseFloat(producto.descuento) : 0),
      0
    );
    const iva = subtotal * 0.16; // Suponiendo que el IVA es del 16%
    const total = subtotal - descuentoTotal + iva;

    return (
      <div className="resumen-cotizacion-container">
        <button className="cerrar-button" onClick={onClose}>
          <i className="bi bi-x-lg"></i>
        </button>
        <div className="resumen-cotizacion-actions">
          <div
            className="dropdown"
            onMouseEnter={() => setShowOptions(true)}
            onMouseLeave={() => setShowOptions(false)}
          >
            <button className="dropbtn">
              <i className="bi bi-printer iconResumen"></i> Imprimir / PDF
            </button>
            {showOptions && (
              <div className="dropdown-content">
                <button onClick={handlePrint}>Imprimir</button>
                <PDFDownloadLink
                  style={{ textDecoration: "none" }}
                  document={generatePDF()}
                  fileName="previa_cotizacion.pdf"
                >
                  {({ loading }) =>
                    loading ? (
                      <button disabled>Descargando...</button>
                    ) : (
                      <button>Descargar PDF</button>
                    )
                  }
                </PDFDownloadLink>
              </div>
            )}
          </div>
          <button>
            <i className="bi bi-envelope-fill iconResumen"></i> Correo
          </button>
          <button onClick={handleEdit}>
            <i className="bi bi-pencil-fill iconResumen"></i> Editar
          </button>
          <button>
            <i className="bi bi-receipt iconResumen"></i> Convertir en Factura
          </button>
        </div>
        <div className="cotizacion-header">
          <img
            src="/img/logo-iciamex.png"
            alt="ICIAMEX"
            className="logoCotizacion"
          />
          <div className="border-right"></div>
          <h1 className="cotizacion-title">Cotización</h1>
        </div>
        <div className="resumen-cotizacion-content">
          <h2>No. {cotizacion.numeroCotizacion.toString().padStart(4, "0")}</h2>
          <div style={{ borderBottom: "2px solid #cecece" }}></div>
          <p>Fecha de Cotización: {fechaCotizacion}</p>
          <p>Asunto: {asunto}</p>
          <p>Cliente: {nombreCliente}</p>
          <h3>DESCRIPCIÓN</h3>
          <table className="productos-table">
            <thead>
              <tr>
                <th>Cantidad</th>
                <th>ID</th>
                <th>Descripción</th>
                <th>Precio</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {productosSeleccionados.map((producto) => (
                <tr key={producto.id}>
                  <td>{producto.cantidad}</td>
                  <td>{producto.productoIdEditado}</td>
                  <td>{producto.nombre}</td>
                  <td>
                    $
                    {parseFloat(producto.costo)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                  <td>
                    $
                    {parseFloat(producto.subtotal)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h3>
            Subtotal: $
            {parseFloat(subtotal)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h3>
          <h3>
            Descuento: $
            {parseFloat(descuentoTotal)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h3>
          <h3>
            IVA: $
            {parseFloat(iva)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h3>
          <h3>
            Total: $
            {parseFloat(total)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </h3>
        </div>
      </div>
    );
  }

  return null;
};

export default ResumenCotizacion;
