import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, query, orderBy, limit } from 'firebase/firestore';
import { db } from './firebaseConfig';
import './App.css';
import Sidebar from './components/Sidebar';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import ListadoArticulosVenta from "./pages/articulos-venta";
import ListadoArticulosCompra from "./pages/articulos-compra";
import ListadoClientes from "./pages/clientes";
import ListadoProveedores from "./pages/proveedores";
import ListadoTodos from "./pages/todos";
import Cotizacion from "./pages/cotizacion";
import OrdenDeCompra from "./pages/orden";
import CalendarioGd from "./pages/calendario";
import Tareas from "./pages/tareas";
import Remisiones from "./pages/remisiones";
import Usuarios from "./pages/usuarios";
import Login from './components/Login';
import Register from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './components/AuthContext';
import Dashboard from "./pages/inicio";
import RemisionForm from './components/RemisionForm';
import OrdenForm from './components/OrdenForm';
import Spinner from './components/Spinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function App() {
  const [loading, setLoading] = useState(true); // Agrega el estado de carga
  const [clientes, setClientes] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [remisiones, setRemisiones] = useState([]);
  const [numeroRemision, setNumeroRemision] = useState(null);
  const [ordenes, setOrdenes] = useState([]);
  const [numeroOrden, setNumeroOrden] = useState(null);
  const [proveedores, setProveedores] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Habilita el estado de carga al inicio
  
      try {
        await Promise.all([
          obtenerClientes(),
          obtenerUltimoNumeroRemision(),
          obtenerRemisiones(),
          obtenerProvedores(),
          obtenerUltimoNumeroOrden(),
          obtenerOrdenes(),
        ]);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      } finally {
        setLoading(false); // Deshabilita el estado de carga
      }
    };
  
    fetchData();
  }, []);


  const obtenerClientes = async () => {
    try {
      const clientesSnapshot = await getDocs(collection(db, 'clientes'));
      const listaClientes = clientesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setClientes(listaClientes);
    } catch (error) {
      console.error('Error al obtener los clientes:', error);
    }
  };


  const obtenerUltimoNumeroRemision = async () => {
    try {
      const remisionesRef = collection(db, 'remisiones');
      const q = query(remisionesRef, orderBy('numeroRemision', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      let ultimoNumero = 1;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const ultimaRemision = doc.data();
          ultimoNumero = ultimaRemision.numeroRemision + 1;
        });
      }
      setNumeroRemision(ultimoNumero);
    } catch (error) {
      console.error('Error al obtener el último número de remisión:', error);
    }
  };

  const obtenerRemisiones = async () => {
    try {
      const remisionesSnapshot = await getDocs(collection(db, 'remisiones'));
      const listaRemisiones = remisionesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setRemisiones(listaRemisiones);
    } catch (error) {
      console.error('Error al obtener las remisiones:', error);
    }
  };

  const guardarRemision = async (remisionData) => {
    try {
      if (!remisionData.cliente || !remisionData.asunto || !remisionData.fechaVencimiento || remisionData.productosSeleccionados.length === 0) {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Todos los campos son obligatorios',
        });
        return;
      }
  
      const cliente = clientes.find(cliente => cliente.id === remisionData.cliente);
      if (!cliente) {
        console.error('Cliente no encontrado');
        return;
      }
  
      const remision = {
        ...remisionData,
        nombreCliente: cliente.empresa,
        numeroRemision,
      };
  
      const docRef = await addDoc(collection(db, 'remisiones'), remision);
      console.log('Remisión guardada con ID: ', docRef.id);
      obtenerRemisiones();
      setNumeroRemision(prevNumero => prevNumero + 1);

      navigate('/remisiones');
    } catch (error) {
      console.error('Error al guardar la remisión:', error);
    }
  };

  const obtenerUltimoNumeroOrden = async () => {
    try {
      const ordenesRef = collection(db, 'ordenes');
      const q = query(ordenesRef, orderBy('numeroOrden', 'desc'), limit(1));
      const querySnapshot = await getDocs(q);
      let ultimoNumero = 1;
      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          const ultimaOrden = doc.data();
          ultimoNumero = ultimaOrden.numeroOrden + 1;
        });
      }
      setNumeroOrden(ultimoNumero);
    } catch (error) {
      console.error('Error al obtener el último número de orden:', error);
    }
  };

  const obtenerOrdenes = async () => {
    try {
      const ordenesSnapshot = await getDocs(collection(db, 'ordenes'));
      const listaOrdenes = ordenesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setOrdenes(listaOrdenes);
    } catch (error) {
      console.error('Error al obtener las órdenes:', error);
    }
  };

  const guardarOrden = async (ordenData) => {
    try {
      if (!ordenData.proveedor || !ordenData.asunto || !ordenData.fechaEntrega || ordenData.productosSeleccionados.length === 0) {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Todos los campos son obligatorios',
        });
        return;
      }
  
      const proveedor = proveedores.find(proveedor => proveedor.id === ordenData.proveedor);
      if (!proveedor) {
        console.error('Proveedor no encontrado');
        return;
      }
  
      const orden = {
        ...ordenData,
        nombreProveedor: proveedor.empresa,
        numeroOrden,
      };
  
      const docRef = await addDoc(collection(db, 'ordenes'), orden);
      console.log('Orden guardada con ID: ', docRef.id);
      obtenerOrdenes();
      setNumeroOrden(prevNumero => prevNumero + 1);

      navigate('/orden');
    } catch (error) {
      console.error('Error al guardar la orden:', error);
    }
  };

  const obtenerProvedores = async () => {
    try {
      const proveedoresSnapshot = await getDocs(collection(db, 'proveedores'));
      const listaProveedores = proveedoresSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProveedores(listaProveedores);
    } catch (error) {
      console.error('Error al obtener los proveedores:', error);
    }
  };

  return (
    <div className="App">
      {loading ? ( // Mostrar el Spinner mientras se carga la aplicación
        <Spinner />
      ) : (
        <AuthProvider>
          <div>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/registro' element={<Register />} />
            <Route path='/' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path='/orden' element={<PrivateRoute><OrdenDeCompra /></PrivateRoute>} />
            <Route path='/cotizacion' element={<PrivateRoute><Cotizacion /></PrivateRoute>} />
            <Route path="/remision-form" element={<PrivateRoute>
            <RemisionForm
              guardarRemision={guardarRemision}
              remisiones={remisiones}
              numeroRemision={numeroRemision}
              clientes={clientes}
            />
            </PrivateRoute>} />
            <Route path="/orden-form" element={<PrivateRoute>
            <OrdenForm
              guardarOrden={guardarOrden}
              ordenes={ordenes}
              proveedores={proveedores}
              numeroOrden={numeroOrden}
            />
            </PrivateRoute>} />
            <Route path='/proveedores' element={<PrivateRoute><ListadoProveedores /></PrivateRoute>} />
            <Route path='/clientes' element={<PrivateRoute><ListadoClientes /></PrivateRoute>} />
            <Route path='/todos' element={<PrivateRoute><ListadoTodos /></PrivateRoute>} />
            <Route path='/articulos-venta' element={<PrivateRoute><ListadoArticulosVenta /></PrivateRoute>} />
            <Route path='/articulos-compra' element={<PrivateRoute><ListadoArticulosCompra /></PrivateRoute>} />
            <Route path='/calendario' element={<PrivateRoute><CalendarioGd /></PrivateRoute>} />
            <Route path='/tareas' element={<PrivateRoute><Tareas /></PrivateRoute>} />
            <Route path='/remisiones' element={<PrivateRoute><Remisiones /></PrivateRoute>} />
            <Route path='/usuarios' element={<PrivateRoute><Usuarios /></PrivateRoute>} />
          </Routes>
          {location.pathname !== '/login' && location.pathname !== '/registro' && <Sidebar />}
        </div>
        </AuthProvider>
      )}
    </div>
  );
}

export default App;
