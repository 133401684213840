import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import SearchBar from './SearchBar';
import Notificaciones from './Notificaciones';
import FullScreen from './FullScreen';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const Nav = ({ proximasAVencer, proximosEventos, handleSearch }) => {
  const { user, logout } = useAuth();
  const displayNameParts = user?.displayName?.split(' ') || [];
  const firstName = displayNameParts[0] || 'Invitado';
  const lastName = displayNameParts.slice(1).join(' ') || '';
  const [userRole, setUserRole] = useState(localStorage.getItem('userRole') || '');
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchUserRole = useCallback(async () => {
    try {
      if (user) {
        const userQuery = query(collection(db, 'usuarios'), where('email', '==', user.email));
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          setUserRole(userData.rol);
          localStorage.setItem('userRole', userData.rol); // Guardar el rol en localStorage
        } else {
          setUserRole('Rol no encontrado');
        }
      }
    } catch (error) {
      console.error('Error al obtener el rol del usuario:', error);
      setUserRole('Error al cargar rol');
    }
  }, [user]);

  useEffect(() => {
    if (user && !localStorage.getItem('userRole')) {
      fetchUserRole();
    }
  }, [fetchUserRole, user]);

  const handleClick = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLogout = async () => {
    await logout();
    localStorage.removeItem('userRole'); // Eliminar el rol de localStorage al cerrar sesión
    navigate('/login');
  };

  // Mapeo de rutas a títulos
  const pageTitles = {
    '/inicio': 'Dashboard',
    '/tareas': 'Tareas',
    '/calendario': 'Calendario',
    '/cotizacion': 'Cotizaciones',
    '/cotizacion-form': 'Cotizaciones',
    '/remisiones': 'Lista de remisiones',
    '/orden': 'Órdenes de Compra',
    '/articulos-venta': 'Artículos de venta',
    '/articulos-compra': 'Artículos de compra',
    '/todos': 'Contactos',
    '/clientes': 'Clientes',
    '/proveedores': 'Proveedores',
    '/usuarios': 'Usuarios'
  };

  const currentTitle = pageTitles[location.pathname] || 'Dashboard';

  return (
    <div style={{ position: 'relative', padding: '5px', boxShadow: '0 1px 5px rgba(0,0,0,0.1)', backgroundColor: '#FFF' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0' }}>
        <div style={{ left: "20%", position: "relative" }}></div>
        <div style={{ marginLeft: '130px' }}>
          <h3 style={{
              fontWeight: 'bold', 
              margin: 0, 
              fontFamily: "Monsterat, sans-serif",
              letterSpacing: '0.5px', 
              color: '#333' // Ajusta este color según el esquema de colores de tu aplicación 
          }}>
            {currentTitle}
          </h3>
        </div>
        <div className={`search-bar-container ${menuVisible ? 'shifted' : ''}`}>
          <SearchBar handleSearch={handleSearch} />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '30px', marginRight: '20px' }}>
          <Notificaciones proximasAVencer={proximasAVencer} proximosEventos={proximosEventos} />
          <i className="bi bi-moon" style={{ cursor: 'pointer', fontSize: '1.5rem' }}></i>
          <FullScreen /> 
          <div style={{ position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center', backgroundColor: "#f7f9f9", width: "200px", padding: '5px', borderRadius: '5px' }}>
              <img src={user?.avatarUrl || '/img/avatar.jpg'} alt="Avatar" style={{ width: '45px', height: '45px', borderRadius: '50%' }} />
              <div style={{ marginLeft: '25px'}}>
                <div style={{ fontWeight: "bold", fontSize: "14px"}}>{firstName} {lastName}</div>
                <div style={{ fontWeight: "normal", fontSize: "14px" }}>{userRole}</div>
              </div>
              <i className="bi bi-caret-down-fill" onClick={handleClick} style={{ marginLeft: '10px', fontSize: '1.2rem', cursor: 'pointer' }}></i>
            </div>
            {menuVisible && (
              <div style={{ position: 'absolute', top: '60px', right: '0', backgroundColor: '#FFFFFF', boxShadow: '0 1px 5px rgba(0,0,0,0.1)', borderRadius: '5px', padding: '10px', zIndex: 1 }}>
                <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                  Cerrar sesión
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
