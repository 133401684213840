import React, { useState } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

// Establece la función de inicialización de react-modal para evitar un aviso de desenfoque de accesibilidad
Modal.setAppElement('#root');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    maxWidth: '450px',
    width: '100%',
    maxHeight: '250px',
    overflow: 'auto',
    fontFamily: 'Roboto, sans-serif',
  },
  label: {
    display: 'block',
    marginBottom: '10px',
  },
  input: {
    width: '100%',
    padding: '8px',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
};

const ProductButton = ({ onClick }) => {
  const [percentage, setPercentage] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const updatePrices = async (increase) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Estás a punto de " + (increase ? "aumentar" : "disminuir") + " los precios.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, ¡hazlo!',
      cancelButtonText: 'No, cancelar'
    });

    if (result.isConfirmed) {
      onClick(increase, percentage); // Llama a la función pasada como prop
      setModalIsOpen(false);
    } else {
      Swal.fire({
        title: 'Cancelado',
        text: 'Los cambios en los precios han sido cancelados.',
        icon: 'info',
        confirmButtonText: 'OK'
      });
      setModalIsOpen(false);
    }
  };

  const handlePercentageChange = (e) => {
    let newValue = e.target.value;
    if (newValue === '0') {
      newValue = '';
    }
    setPercentage(newValue);
  };

  return (
    <div>
      <button className="prices-button button-container-fixed" onClick={() => setModalIsOpen(true)}> + Ajustar precios</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{...customStyles, content: { ...customStyles.content, width: '50%' }}}
        contentLabel="Ajuste de Precios"
      >
        <button className="modal-close" onClick={() => setModalIsOpen(false)}>x</button>
        <h2 style={{textAlign: "center"}}>Editar Ajuste de Precios</h2>
        <label style={customStyles.label}>
          Porcentaje:
          <input type="number" value={percentage} onChange={handlePercentageChange} style={customStyles.input} />
        </label>
        <button className="modal-apply-button" onClick={() => updatePrices(true)}>Aumentar Precio</button>
        <button className="modal-apply-button" onClick={() => updatePrices(false)}>Disminuir Precio</button>
      </Modal>
    </div>
  );
};

export default ProductButton;
